import React, { useState, useEffect } from 'react';
import Pagination from '../Blog/Pagination/Pagination';
import CardContainer from './CardContainer';

// const RenderContent = ({ blogPosts }) => {
//     console.log(blogPosts);
//     const [page, setPage] = useState(1);
//     return (
//         <>
//             <CardContainer blogPosts={blogPosts && blogPosts} />
//             <Pagination currentPage={page} setPage={setPage} />
//         </>
//     )
// };

const Tabs = () => {
    const [specialDealCars, setSpecialDealCars] = useState([]);
    // const [activeTab, setActiveTab] = useState('dashboard');
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        const init = async () => {
            try {
                const queryParams = new URLSearchParams();
        
                queryParams.append('page', page);
                queryParams.append('limit', 6);

                // Fetch the data with the applied filters
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/car/public/special_deals?${queryParams.toString()}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
        
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
        
                const data = await response.json();

                if (data?.data && data?.data?.cars) {
                    const posts = data?.data.cars.map((car) => ({
                      id: car.id,
                      name: car.name,
                      heading: `${car.vehicleType}`,
                      image: Array.isArray(car.photos) ? car.photos[0] : car.photos,
                      price: car.monthly_payment.toString(),
                      lease: `${car.leaseTerm}`,
                      mile: car.miles,
                      seat: car.seats.toString(),
                      discount: "-40%",
                      transType: car.transType
                    }));
              
                    setPage(data?.data?.page || 1);
                    setSpecialDealCars(posts || []);
                    setLastPage(data?.data?.lastPage);
                  }

            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };
        init();
    }, [page]);

    useEffect(() => {
        console.log("special deal cars in render component", specialDealCars)
    },[specialDealCars])

    return (
        <>
            <div className="mt-20 " style={{maxWidth: "100vw", overflow: "hidden"}}>
                <div className="lg:text-[45px] text-[35px] font-[700]">
                    Current Special Lease Deals
                </div>
                <div className="text-[14px] font-[500] text-[#959595] my-5">
                    Explore our current special lease offers and discover how easy it is to drive a new car:
                </div>
                <div className="mb-4">
                    {/* <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" role="tablist">
                        <li className="me-2" role="presentation">
                            <button
                                className={`text-[14px] lg:mt-0 mt-2 h-[48px] flex justify-center items-center px-4 rounded-[43px] ${activeTab === 'dashboard' ? 'bg-black text-white border-black ' : 'bg-[#F8F8F8] text-black border-transparent'}`}
                                onClick={() => setActiveTab('dashboard')}
                                role="tab"
                                aria-controls="dashboard"
                                aria-selected={activeTab === 'dashboard'}
                            >
                                Luxury&nbsp;Sedans
                            </button>
                        </li>
                        <li className="me-2" role="presentation">
                            <button
                                className={`text-[14px] lg:mt-0 mt-2 h-[48px] flex justify-center items-center px-4 rounded-[43px] ${activeTab === 'settings' ? 'bg-black text-white border-black ' : 'bg-[#F8F8F8] text-black border-transparent'}`}
                                onClick={() => setActiveTab('settings')}
                                role="tab"
                                aria-controls="settings"
                                aria-selected={activeTab === 'settings'}
                            >
                                Family-Friendly&nbsp;SUVs
                            </button>
                        </li>
                        <li className="me-2" role="presentation">
                            <button
                                className={`text-[14px] lg:mt-0 mt-2 h-[48px] flex justify-center items-center px-4 rounded-[43px] ${activeTab === 'contacts' ? 'bg-black text-white border-black ' : 'bg-[#F8F8F8] text-black border-transparent'}`}
                                onClick={() => setActiveTab('contacts')}
                                role="tab"
                                aria-controls="contacts"
                                aria-selected={activeTab === 'contacts'}
                            >
                                Performance&nbsp;Sports
                            </button>
                        </li>
                    </ul> */}
                </div>
                <div id="default-tab-content">
                    <CardContainer blogPosts={specialDealCars && specialDealCars} />
                    <Pagination currentPage={page} setPage={setPage} totalPages={lastPage} />

                    {/* <div className={`${activeTab === 'profile' ? '' : 'hidden'}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <RenderContent blogPosts={blogPosts} />
                    </div>
                    <div className={` ${activeTab === 'dashboard' ? '' : 'hidden'}`} id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                        <RenderContent blogPosts={blogPosts} />
                    </div>
                    <div className={` rounded-lg ${activeTab === 'settings' ? '' : 'hidden'}`} id="settings" role="tabpanel" aria-labelledby="settings-tab">
                        <RenderContent blogPosts={blogPosts} />
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default Tabs;
