import React, { useState, useRef, useContext, useEffect } from "react";
import Card from "../../General/Card";
import Icon from "./icon/icon";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import { context } from "../../../context/context";

const Features = ({ heading = "Featured vehicles", slides = 3.5 }) => {
  const [cards, setCards] = useState([]);
  const [viewSlides, setViewSlides] = useState(3);
  const [active, setActive] = useState({
    left: "#0E0E0E",
    right: "#0E0E0E",
  });

  const {featureIndex} = useContext(context);

  const [hovered, setHovered] = useState(false); // State for handling hover

  const swiperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1441) {
        setViewSlides(3.5);
      } else  if (window.innerWidth >= 1024) {
        setViewSlides(3);
      } else {
        setViewSlides(1);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const init = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/car/public/featured`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();

            if (data?.data && data?.data?.cars) {
              const tempCards = data?.data.cars.map((car) => ({
                id: car.id,
                name: car.name,
                heading: `${car.vehicleType}`,
                image: Array.isArray(car.photos) ? car.photos[0] : car.photos,
                price: car.monthly_payment.toString(),
                lease: `${car.leaseTerm}`,
                mile: car.miles,
                seat: car.seats.toString(),
                discount: "-40%",
                transType: car.transType
              }));

              setCards(tempCards);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    init();
  }, []);

  const onClickRight = () => {
    if (swiperRef.current) {
      const totalSlides = cards.length;
      
      if (totalSlides > viewSlides) {
        swiperRef.current.slideNext();
      }
    }
  };

  const onClickLeft = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <div className="lg:w-full md:w-full w-[350px]">
      <div className=" container">
        <div className="mt-[10%] overflow-hidden">
          {/* Header Section */}
          <div className="lg:w-full md:w-[95%] w-[350px] max-w-full mx-auto flex justify-between items-center">
            <div
              className="heading font-[700] lg:text-[45px] md:text-[45px] text-[35px]  text-black "
              style={{ letterSpacing: "-1px" }}
            >
              {heading}
            </div>
            <div className=" gap-2 px-7 lg:flex md:flex hidden">
              <motion.div
                className="bg-lightgray w-[44px] h-[44px] flex justify-center items-center rounded-full featureLeftIcon transition-all duration-300"
                onClick={onClickLeft}
                onHoverStart={() => {
                  setActive({
                    left: "#fff",
                    right: "#0E0E0E",
                  });
                }}
                onHoverEnd={() => {
                  setActive({
                    left: "#0E0E0E",
                    right: "#0E0E0E",
                  });
                }}
              >
                <div className="featureLeftIconInner transition-all duration-200">
                  <Icon color={active.left} />
                </div>
                <div className="featureLeftLine transition-all duration-200 absolute"></div>
              </motion.div>

              <div
                className="bg-lightgray w-[44px] h-[44px] flex justify-center items-center rounded-full transition-all duration-300 featureLeftIcon"
                onClick={onClickRight}
                onMouseEnter={() => {
                  setActive({
                    left: "#0E0E0E",
                    right: "#fff",
                  });
                }}
                onMouseLeave={() => {
                  setActive({
                    left: "#0E0E0E",
                    right: "#0E0E0E",
                  });
                }}
              >
                <div className="rotate-180 transition-all duration-200 featureRightInner">
                  <Icon color={active.right} />
                </div>
                <div className="featureLeftLine transition-all duration-200 absolute"></div>
              </div>
            </div>
          </div>

          {/* Cards Section */}
          <div className="flex lg:gap-5 gap-[6px] lg:w-full md:w-full w-auto max-w-full overflow-hidden mx-auto -z-20 ">
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              speed={1000}
              grabCursor={false}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 28,
                  grabCursor: true
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  grabCursor: true
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                  grabCursor: false
                },
                1441: {
                  slidesPerView: 3.5,
                  spaceBetween: 20,
                  grabCursor: false
                },
              }}
              style={{zIndex: featureIndex}}
            >
              {cards.map((card, index) => (
                <SwiperSlide key={index}>
                  <Card obj={card} />
                </SwiperSlide>
              ))}
              {(cards && cards.length < Math.ceil(viewSlides)) &&
                Array.from({ length: Math.ceil(viewSlides) - cards.length }).map(
                  (_, index) => (
                    <SwiperSlide key={`empty-${index}`}>
                      <div className="w-full h-[240px] bg-transparent"></div>{" "}
                      {/* Empty slide */}
                    </SwiperSlide>
                  )
                )
              }
            </Swiper>
          </div>

          {/* View All Button */}
          <div className="flex justify-center items-center">
            <Link to="/view">
              <button
                className="lg:w-[340px] md:w-[340px] w-[325px] h-[44px] rounded-[10px] flex justify-center items-center text-[14px] mt-[10px] hoverAni6 font-[500] relative"
                onMouseEnter={() => setHovered(true)} // Set hover state to true
                onMouseLeave={() => setHovered(false)} // Set hover state to false
              >
                View All Vehicles{" "}
                <div className="relative">
                  <div className="hoverLine2"></div>
                </div>
                <svg
                  className="hoverSVG w-[10px] h-[10px] ml-2 imahe1"
                  width="9"
                  height="13"
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 6.5L1 12"
                    className="svgPath"
                    stroke={`${!hovered? "#000000": "#FFB600"}`}
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
