import React, { useEffect, useState } from 'react'
import Dropdown from './Dropdown'
import { img } from 'framer-motion/client';

const vehicleModels = {
    "Ford": ["F-150", "Mustang", "Explorer", "Escape", "Bronco", "Expedition", "Edge", "Ranger", "Maverick", "Super Duty"],
    "Chevrolet": ["Silverado", "Corvette", "Camaro", "Tahoe", "Suburban", "Malibu", "Blazer", "Equinox", "Traverse", "Colorado", "Trailblazer"],
    "Toyota": ["Camry", "Corolla", "RAV4", "Tacoma", "Tundra", "Highlander", "4Runner", "Prius", "Sienna", "Land Cruiser"],
    "Honda": ["Accord", "Civic", "CR-V", "Pilot", "Odyssey", "Passport", "HR-V", "Ridgeline", "Insight"],
    "Jeep": ["Wrangler", "Grand Cherokee", "Cherokee", "Gladiator", "Renegade", "Compass", "Wagoneer"],
    "Tesla": ["Model 3", "Model S", "Model X", "Model Y", "Cybertruck (Upcoming)", "Roadster (Upcoming)"],
    "Ram": ["1500", "2500", "3500", "ProMaster", "TRX", "ProMaster City"],
    "Nissan": ["Altima", "Sentra", "Rogue", "Murano", "Pathfinder", "Frontier", "Titan", "Maxima", "Armada", "Kicks", "Leaf"],
    "BMW": ["3 Series", "5 Series", "7 Series", "X1", "X3", "X5", "X7", "4 Series", "M3", "M4", "M4", "Z4", "i3 (Discontinued)", "i4", "iX", "M8"],
    "Mercedes-Benz": ["A-Class", "C-Class", "E-Class", "S-Class", "G-Class", "GLA", "GLB", "GLC", "GLE", "GLS", "AMG GT", "EQS (Electric)", "EQE (Electric)"],
    "Subaru": ["Outback", "Forester", "Ascent", "Impreza", "WRX", "Crosstrek", "Legacy", "BRZ"],
    "GMC": ["Sierra 1500", "Sierra 2500HD", "Yukon", "Acadia", "Terrain", "Canyon", "Hummer EV"],
    "Dodge": ["Challenger", "Charger", "Durango", "Journey (Discontinued)", "Hornet", "Viper (Discontinued)", "Grand Caravan (Discontinued)"],
    "Volkswagen": ["Jetta", "Passat", "Golf", "Tiguan", "Atlas", "ID.4 (Electric)", "Arteon", "Taos"],
    "Hyundai": ["Elantra", "Sonata", "Tucson", "Santa Fe", "Palisade", "Kona", "Venue", "Ioniq 5 (Electric)", "Veloster N"],
    "Ferrari": ["488", "F8 Tributo", "SF90 Stradale", "812 Superfast", "Roma", "Portofino", "LaFerrari", "Purosangue (SUV)"],
    "Lamborghini": ["Aventador", "Huracán", "Urus", "Sián", "Revuelto (Upcoming)"],
    "Porsche": ["911", "Cayenne", "Macan", "Panamera", "Taycan (Electric)", "718 Boxster", "718 Cayman"],
    "Aston Martin": ["Vantage", "DB11", "DBX", "DBS Superleggera", "Valhalla (Upcoming)", "Valkyrie"],
    "McLaren": ["720S", "570S", "GT", "600LT", "Artura (Hybrid)", "Senna", "Speedtail"],
    "Rolls-Royce": ["Phantom", "Ghost", "Wraith", "Dawn", "Cullinan", "Spectre (Upcoming Electric)"],
    "Bentley": ["Bentayga", "Continental GT", "Flying Spur", "Mulsanne (Discontinued)"],
    "Maserati": ["Ghibli", "Quattroporte", "Levante", "MC20", "GranTurismo (Upcoming)"],
    "Bugatti": ["Chiron", "Veyron (Discontinued)", "Divo", "Centodieci", "Bolide"],
    "Lotus": ["Evora", "Elise", "Exige", "Emira",],
    "Audi": ["A3","A4","A5","A6","A7","A8","Q3","Q5","Q7","Q8","TT","R8","e-tron","e-tron GT"],
    "Lexus": ["IS","ES","LS","NX","RX","GX","LX","RC","LC","UX","RZ"],
    "Volvo": ["S60","S90","V60","V90","XC40","XC60","XC90","C40 Recharge","EX90"],
    "Kia": ["Forte","K5","Stinger","Soul","Seltos","Sportage","Sorento","Telluride","Carnival","Niro","EV6","EV9"],
    "Alfa Romeo": ["Giulia","Stelvio","Tonale","Giulia Quadrifoglio","Stelvio Quadrifoglio"],
    "Jaguar": ["XE","XF","F-Type","E-PACE","F-PACE","I-PACE"],
    "Land Rover": ["Range Rover","Range Rover Sport","Range Rover Velar","Range Rover Evoque","Discovery","Defender"],
    "Lincoln": ["Corsair","Nautilus","Aviator","Navigator"],
    "Mazda": ["Mazda3","Mazda6","CX-3","CX-30","CX-5","CX-50","CX-9","MX-5 Miata"]
};

const Modal = ({ closeModal, heading = "Add new vehicle", carId = null, name }) => {
    // States for each field
    const [carName, setCarName] = useState(carId ? name : '');
    const [category, setCategory] = useState('');
    const [model, setModel] = useState('');
    const [brand, setBrand] = useState('');
    const [leaseTerm, setLeaseTerm] = useState('');
    const [milesPerYear, setMilesPerYear] = useState('');
    const [monthlyPayment, setMonthlyPayment] = useState('');
    const [price, setPrice] = useState('');
    const [numSeats, setNumSeats] = useState('');
    const [transmission, setTransmission] = useState('');
    const [numCylinders, setNumCylinders] = useState('');
    const [images, setImages] = useState([]);
    const [vehicleType, setVehicleType] = useState('');
    // const [description, setDescription] = useState('');
    const [vehicleModelOptions, setVehicleModelOptions] = useState(null);
    const [specialDealOption, setSpecialDealOption] = useState(null);
    const [featuredOption, setFeaturedOption] = useState(null);
    // Fetch car details if carId is present (edit mode)
    useEffect(() => {
        if (carId) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/car/public/${carId}`)  // Replace with your actual API endpoint
                .then(response => response.json())
                .then(data => {
                    setCarName(data?.data?.carName);
                    setCategory(data?.data.category);
                    setModel(data?.data.model);
                    setBrand(data?.data.brand);
                    setLeaseTerm(data?.data.leaseTerm);
                    setMilesPerYear(data?.data.miles);
                    setMonthlyPayment(data?.data.monthly_payment);
                    setPrice(data?.data.price);
                    setNumSeats(data?.data.seats);
                    setTransmission(data?.data.transType);
                    setNumCylinders(data?.data.cylinder);
                    setVehicleType(data?.data.vehicleType)
                    setImages(data?.data.photos)
                    setSpecialDealOption(data?.data.specialDeal)
                    setFeaturedOption(data?.data.featured)
                    // Add logic for setting existing images if needed
                })
                .catch(err => console.error(err));
        }
    }, [carId]);

    const [step, setStep] = useState(1);

    // Handle file uploads (image files)
    const handleFileChange = async (e) => {

        const cloudinaryUploadURL = 'https://api.cloudinary.com/v1_1/dbzjzgav7/image/upload';
        const uploadPreset = 'wheeldeals';
        const selectedFiles = e.target.files[0];

        const formData = new FormData();
        formData.append('file', selectedFiles);
        formData.append('upload_preset', uploadPreset);


        try {
            // Upload image to Cloudinary
            const response = await fetch(cloudinaryUploadURL, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();
            console.log(data.secure_url);
            if (data.secure_url) {

                setImages([...images, data.secure_url]);
            } else {
                alert('Error uploading image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Error uploading image');
        }

        e.target.value = ''; 
        // setImages([...images, ...base64Images]);
    };

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file); // Ensure we read as Data URL to include the MIME type
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    useEffect(() => {
        // setCarName(name + " ")
        setMilesPerYear(milesPerYear + "")
    }, [])

    // Handle form submission
    const saveCar = () => {
        const sanitizedMonthlyPayment = String(monthlyPayment || "").trim();
        setCarName(carName + "     ");
        const carData = {
            name: String(carName || '').trim(),
            category: String(category || '').trim(),
            model: String(model || '').trim(),
            brand: String(brand || '').trim(),
            leaseTerm: String(leaseTerm || '').trim(),
            miles: String(milesPerYear || '').trim(),
            monthly_payment: sanitizedMonthlyPayment,
            price: String(price || '').trim(),
            seats: String(numSeats || '').trim(),
            transType: String(transmission || '').trim(),
            cylinder: String(numCylinders || '').trim(),
            vehicleType: String(vehicleType || '').trim(),
            specialDeal: String(specialDealOption || ''),
            featured: String(featuredOption || ''),
            photos: images,
        };


        const url = carId ? `${process.env.REACT_APP_BACKEND_URL}/car/private/${carId}` : `${process.env.REACT_APP_BACKEND_URL}/car/private`;
        const method = carId ? 'POST' : 'POST';

        fetch(url, {
            method: method,
            body: JSON.stringify(carData),
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                if (response.ok) {
                    // closeModal();
                    setStep(4);
                } else {
                    console.error('Failed to save car');
                    alert("You must fill out all fields for new car!");
                }
            })
            .catch(err => console.error('Error:', err));
    };

    useEffect(() => {
        setVehicleModelOptions(vehicleModels[brand]);
    }, [brand]);

    useEffect(() => {
        // console.log("Images are", images);
    }, [images])

    return (
        <div className='w-[100vw] h-[100vh] flex fixed justify-center items-center top-0 left-0' style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            {/* {model 1 here use name and description and set them} */}.
            <div className="w-[690px] " style={{ display: step === 4 ? "none" : "block" }}>
                {step === 1 && <div className="w-[690px] flex flex-wrap rounded-[15px] h-[95vh] max-h-[778px] bg-white px-10 overflow-y-auto overflow-x-hidden">
                    <div className="">

                        <div className="head flex text-[16px] text-[#767676] w-[650px]  font-[500]  mt-[36px]">
                            <div className="">
                                {carId ? "Edit vehicle" : "Add new vehicle"}
                            </div>
                            <div className="ml-[30px] text-[500] text-[#B9B9B9]">
                                Step 1/3
                            </div>
                        </div>

                        <div className="head text-[30px] font-[700] text-black mt-[36px]">
                            {carId ? "Car Name" : "Add car name"}
                        </div>
                    </div>
                    <div className="flex flex-col justify-between flex-wrap pt-4 gap-3 h-[80%]">
                        <div className="">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Name
                            </div>
                            <input type='text' placeholder='Enter car name' className="flex items-center justify-between  cursor-pointer bg-[#F8F8F8] w-[600px] h-[50px] rounded-xl px-5 text-[14px] font-[400] mt-4" value={carName} onChange={(e) => setCarName(e.target.value)} />
                            <div className='flex items-center gap-8'>
                                <div>
                                    <div className="text-[#767676] text-[12px] font-[500] mt-8">
                                        Special Deal
                                    </div>
                                    <Dropdown options={['Yes', 'No']}  optionCase={carId ? "edit" : "add"} label={brand || 'Select Yes / No'} onSelect={setSpecialDealOption} />
                                </div>
                                <div>
                                    <div className="text-[#767676] text-[12px] font-[500] mt-8">
                                    Add vehicle to Featured
                                    </div>
                                    <Dropdown options={['Yes', 'No']}  optionCase={carId ? "edit" : "add"} label={brand || 'Select Yes / No'} onSelect={setFeaturedOption} />
                                </div>
                            </div>
                        </div>
                        <div className="w-[600px] mt-5 flex justify-end gap-5 font-[500] pb-[30px]">
                            <button className="" onClick={closeModal}>
                                Cancel
                            </button>
                            <button className="bg-black text-white px-8 py-4 rounded-[15px]" onClick={() => { setStep(2); setCarName(carName + ' ') }}>
                                Next
                            </button>
                        </div>
                    </div>

                </div>}
                {step === 2 && <div className="w-[690px]  flex-wrap rounded-[15px] h-[95vh] max-h-[778px] bg-white px-10 overflow-y-auto overflow-x-hidden vehicleModal">
                    <div className="head flex text-[16px] text-[#767676] w-[650px]  font-[500]  mt-[36px]">
                        <div className="">
                            {carId ? "Edit vehicle" : "Add new vehicle"}
                        </div>
                        <div className="ml-[30px] text-[500] text-[#B9B9B9]">
                            Step 2/3
                        </div>
                    </div>

                    <div className="head text-[30px] font-[700] text-black mt-[36px]">
                        Vehicle details
                    </div>
                    <div className="flex flex-wrap pt-4 gap-3">
                        <div className="w-[284px]">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Vehicle Brand
                            </div>

                            <div className="">
                                <Dropdown options={['Ford', 'Chevrolet', 'Toyota', 'Honda', 'Jeep', 'Tesla', 'Ram', 'Nissan', 'BMW', 'Mercedes-Benz', 'Subaru', 'GMC', 'Dodge', 'Volkswagen', 'Hyundai', 'Ferrari', 'Lamborghini', 'Porsche', 'Aston Martin', 'McLaren', 'Rolls-Royce', 'Bentley', 'Maserati', 'Bugatti', 'Lotus', 'Audi', 'Lexus', 'Volvo', 'Kia', 'Alfa Romeo', 'Jaguar', 'Land Rover', 'Lincoln', 'Mazda']}  optionCase={carId ? "edit" : "add"} label={brand || 'Ford'} onSelect={setBrand} />
                            </div>
                        </div>

                        {/* <div className="w-[284px]">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Choose Category
                            </div>

                            <div className="">
                                <Dropdown options={['Sport', 'SUVs ', 'Hatchback', 'Crossover', 'Sedan', 'Electric', 'Hybrid', 'Pickup']} optionCase={carId ? "edit" : "add"} label={category || 'Sport'} onSelect={setCategory} />
                            </div>
                        </div> */}

                        <div className="w-[284px]">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Vehicle Model
                            </div>

                            <div className="">
                                <Dropdown options={vehicleModelOptions} label={model || 'Model Y'} optionCase={carId ? "edit" : "add"} onSelect={setModel} />
                            </div>
                        </div>

                        <div className="w-[284px]">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Vehicle Type
                            </div>

                            <div className="">
                                <Dropdown options={['Coupe', 'Sedan', 'SUV', 'Crossover', 'Convertible', 'Van']} optionCase={carId ? "edit" : "add"} label={vehicleType || 'Choose Vehicle Type'} onSelect={setVehicleType} />
                            </div>
                        </div>

                        <div className="w-[284px]">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Transmission type
                            </div>

                            <div className="">
                                <Dropdown options={['Automatic', 'Manual']} type="transmission" optionCase={carId ? "edit" : "add"} label={transmission || 'Select transmission type'} onSelect={setTransmission} />
                            </div>
                        </div>

                        <div className="">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Miles per year
                            </div>

                            <input type='text' placeholder='10,000 miles/year' className="flex items-center justify-between  cursor-pointer bg-[#F8F8F8] w-[284px] h-[50px] rounded-xl px-5 text-[14px] font-[400] mt-4" value={milesPerYear} onChange={(e) => setMilesPerYear(e.target.value)} />


                        </div>

                        <div className="">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Monthly Payment
                            </div>

                            <input type='text' placeholder='$1080/month' className="flex items-center justify-between  cursor-pointer bg-[#F8F8F8] w-[284px] h-[50px] rounded-xl px-5 text-[14px] font-[400] mt-4" value={monthlyPayment} onChange={(e) => setMonthlyPayment(e.target.value)} />
                        </div>

                        <div className="w-[284px]">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Lease Term
                            </div>

                            <div className="">
                                <Dropdown options={['12 months', '24 months ', '36 months']} optionCase={carId ? "edit" : "add"} label={leaseTerm || 'Choose Lease Term'} onSelect={setLeaseTerm} />
                            </div>
                        </div>

                        <div className="">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Price
                            </div>

                            <input type='text' placeholder='$25000' className="flex items-center justify-between  cursor-pointer bg-[#F8F8F8] w-[284px] h-[50px] rounded-xl px-5 text-[14px] font-[400] mt-4" value={price} onChange={(e) => setPrice(e.target.value)} />
                        </div>


                        <div className="">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Number of Seats
                            </div>

                            <input type='text' placeholder='5' className="flex items-center justify-between  cursor-pointer bg-[#F8F8F8] w-[284px] h-[50px] rounded-xl px-5 text-[14px] font-[400] mt-4" value={numSeats} onChange={(e) => setNumSeats(e.target.value)} />


                        </div>

                        <div className="">
                            <div className="text-[#767676] text-[12px] font-[500]">
                                Number of Cylinders
                            </div>

                            <input type='text' placeholder='3' className="flex items-center justify-between  cursor-pointer bg-[#F8F8F8] w-[284px] h-[50px] rounded-xl px-5 text-[14px] font-[400] mt-4" value={numCylinders} onChange={(e) => setNumCylinders(e.target.value)} />

                        </div>
                    </div>


                    <div className="w-[600px] flex justify-between font-[500] pb-[30px] items-center">
                        <button className="mt-10 flex gap-1 justify-center items-center" onClick={() => setStep(step - 1)}>
                            <img src={require("../../images/back.png")} alt="" />
                            Back
                        </button>
                        <div className=" mt-10 flex justify-end gap-5 ">
                            <button className="" onClick={closeModal}>
                                Cancel
                            </button>
                            <button className="bg-black text-white px-8 py-4 rounded-[15px]" onClick={() => setStep(3)}>
                                Next
                            </button>
                        </div>
                    </div>
                </div>}
                {step === 3 && <div className="w-[690px] flex flex-wrap rounded-[15px] h-[95vh] max-h-[778px] bg-white px-10 overflow-y-auto overflow-x-hidden">
                    <div className="">

                        <div className="head flex text-[16px] text-[#767676] w-[650px]  font-[500]  mt-[36px]">
                            <div className="">
                                {carId ? "Edit vehicle" : "Add new vehicle"}
                            </div>
                            <div className="ml-[30px] text-[500] text-[#B9B9B9]">
                                Step 3/3
                            </div>
                        </div>

                        <div className="head text-[30px] font-[700] text-black mt-[36px]">
                            {"Add photos"}
                        </div>
                    </div>
                    <div className="flex flex-col justify-between flex-wrap pt-4 gap-3 h-[80%]">
                        <div className="">

                            <div className="text-[12px] font-[400] flex gap-3 text-[#4E4E4E]">
                                <img src={require("../../images/info.png")} alt="" />
                                <div className="">
                                    Upload up to 10 images of any damages or the vehicle condition upon return
                                </div>
                            </div>

                            <div className="w-[80px] mt-10"></div>

                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleFileChange}
                                className="bg-lightgray mt-3 text-[16px] font-[500] px-7 py-4 rounded-[10px] cursor-pointer hidden"
                                id='photoSelector'
                            />
                            <div className="w-[10px]"></div>


                            <label htmlFor='photoSelector' className="bg-lightgray mt-3 text-[16px] font-[500] px-7 py-4 rounded-[10px] cursor-pointer">
                                Browse files
                            </label>
                            <div className="flex gap-2 flex-wrap">
                                {images.map((image, index) => (
                                    <div key={index} className="mt-10 relative w-[138px] h-[136px] mx-1 my-auto">
                                        <img
                                            src={image}
                                            alt="Uploaded Image1"
                                            className="w-full h-full rounded-xl object-contain"
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevents infinite loop if image keeps failing
                                                e.target.src = 'fallback-image-url'; // Set a fallback image if needed
                                                console.error('Image failed to load:', image);
                                            }}
                                        />
                                        <button
                                            onClick={() => {
                                                const updatedImages = images.filter((_, i) => i !== index); // Remove image
                                                setImages(updatedImages);
                                            }}
                                            className="absolute top-[-5px] right-[-5px] w-4 h-4 text-white bg-black rounded-full flex justify-center items-center text-[14px]"
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>


                        <div className="w-[600px] flex justify-between font-[500] pb-[30px] items-center">
                            <button className="mt-10 flex gap-1 justify-center items-center" onClick={() => setStep(step - 1)}>
                                <img src={require("../../images/back.png")} alt="" />
                                Back
                            </button>
                            <div className=" mt-10 flex justify-end gap-5 ">
                                <button className="" onClick={closeModal}>
                                    Cancel
                                </button>
                                <button className="bg-black text-white px-8 py-4 rounded-[15px]" onClick={() => saveCar()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                </div>}


            </div>

            {step === 4 && <div className="w-[400px] flex flex-wrap rounded-[15px] h-[400px] max-h-[400px] bg-white px-10 overflow-y-auto overflow-x-hidden justify-center items-center">
                <div className="flex flex-col items-center justify-between h-[350px] pt-14 relative">
                    <img src={require("../../images/Vector.png")} className='absolute top-[10px] right-[10px] cursor-pointer' onClick={()=>{closeModal(); window.location.reload()}} alt="" />
                    <div className=" flex flex-col items-center">
                        <div className="">
                            <img src={require("../../images/Group 33543.png")} alt="" />
                        </div>
                        <div className="font-[700] text-[30px]">
                            Success!
                        </div>
                        <div className="mt-[25px] text-[14px] text-[#959595] font-[500] text-center">
                            Vehicle is successfully {carId ? "edited" : "added"}
                        </div>
                    </div>
                    <div className="w-[352px] h-[50px]  bg-black text-white justify-center items-center flex rounded-[10px] cursor-pointer" onClick={()=>{closeModal(); window.location.reload()}}>
                        <div className="text-[16px] font-[500]" >
                            Back to Dashboard
                        </div>
                    </div>
                </div>


            </div>}


        </div>
    )
}

export default Modal
