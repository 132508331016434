import React, { useState, createRef } from 'react';
import DatePicker from 'react-datepicker';
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import 'react-datepicker/dist/react-datepicker.css';
import Thankyou from '../General/Thankyou';

const ContactForm = () => {
    const [step, setStep] = useState(1);
    const refCaptcha = createRef();
    const [status, setStatus] = useState(null);

    const defaultContactForm = {
        firstName: "",
        lastName: "",
        email: "",
        homePhone: "",
        cellPhone: "",
        ssn: "",
        birth: "",
        rentAmount: "",
        rentTime: "",
        previousAddress: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        placeEmployment: "",
        employmentTime: "",
        employmentAddress: "",
        employmentCity: "",
        employmentState: "",
        employmentZipcode: "",
        occupation: "",
        annualIncome: "",
        businessName: "",
        businessType: "",
        federalTaxId: "",
        establishedYears: "",
        relationShipCompany: "",
        businessPhone: "",
        businessEmail: "",
        businessAddress: "",
        businessCity: "",
        businessState: "",
        businessZipcode: ""
    };

    const [activeButtonSteps, setActiveButtonSteps] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false
    });

    const [contactForm, setContactForm] = useState(defaultContactForm);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);

    const handleFormChange = (e) => {
        setContactForm({...contactForm, [e.target.name]: e.target.value});

        if(contactForm.firstName !== '' && contactForm.lastName !== '' && contactForm.email !== '' && contactForm.homePhone !== '' && contactForm.cellPhone !== '') {
            setActiveButtonSteps({...activeButtonSteps, step1: true});
        }

        if(contactForm.ssn !== '' && contactForm.birth !== '' && contactForm.rentAmount !== '' && contactForm.rentTime !== '') {
            setActiveButtonSteps({...activeButtonSteps, step2: true});
        }

        if(contactForm.previousAddress !== '' && contactForm.address !== '' && contactForm.city !== '' && contactForm.state !== '' && contactForm.zipCode !== '') {
            setActiveButtonSteps({...activeButtonSteps, step3: true});
        }

        if(contactForm.placeEmployment !== '' && contactForm.employmentTime !== '' && contactForm.employmentAddress !== '' && contactForm.employmentCity !== '' && contactForm.employmentState !== '' && contactForm.employmentZipcode !== '' && contactForm.occupation !== '' && contactForm.annualIncome !== '') {
            setActiveButtonSteps({...activeButtonSteps, step4: true});
        }

        if(contactForm.businessName !== '' && contactForm.businessType !== '' && contactForm.federalTaxId !== '' && contactForm.establishedYears !== '' && contactForm.relationShipCompany !== '') {
            setActiveButtonSteps({...activeButtonSteps, step5: true});
        }

        if(contactForm.businessPhone !== '' && contactForm.businessEmail !== '' && contactForm.businessAddress !== '' && contactForm.businessCity !== '' && contactForm.businessState !== '' && contactForm.businessZipcode !== '') {
            setActiveButtonSteps({...activeButtonSteps, step6: true});
        }
    }

    const handleChangeBirthDate = (date) => {
        setContactForm({...contactForm, birth: date});
    }

    const nextStep = () => {
        if (step < 7) {
            setStep(step + 1);
        }
    };

    const moveStep = (tempStep) => {
        if(tempStep < step) {
            setStep(tempStep);
        }
    }

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const token = refCaptcha.current.getValue();
        setStatus(true);

        try {
            const serviceId = "service_plf400i";
            const templateId = "template_8gw5rrs";
            const publicKey = "4NAtqVGd8_IesGS7W";
      
            await emailjs.send(
              serviceId,
              templateId,
              {
                to_name: "WheelDeal Team",
                to_email: "no-reply@getautoflow.io",
                firstName: contactForm.firstName,
                lastName: contactForm.lastName,
                email: contactForm.email,
                homePhone: contactForm.homePhone,
                cellPhone: contactForm.cellPhone,
                ssn: contactForm.ssn,
                birth: contactForm.birth,
                rentAmount: contactForm.rentAmount,
                rentTime: contactForm.rentTime,
                previousAddress: contactForm.previousAddress,
                address: contactForm.address,
                city: contactForm.city,
                state: contactForm.state,
                zipCode: contactForm.zipCode,
                placeEmployment: contactForm.placeEmployment,
                employmentTime: contactForm.employmentTime,
                employmentAddress: contactForm.employmentAddress,
                employmentCity: contactForm.employmentCity,
                employmentState: contactForm.employmentState,
                employmentZipcode: contactForm.employmentZipcode,
                occupation: contactForm.occupation,
                annualIncome: contactForm.annualIncome,
                businessName: contactForm.businessName,
                businessType: contactForm.businessType,
                federalTaxId: contactForm.federalTaxId,
                establishedYears: contactForm.establishedYears,
                relationShipCompany: contactForm.relationShipCompany,
                businessPhone: contactForm.businessPhone,
                businessEmail: contactForm.businessEmail,
                businessAddress: contactForm.businessAddress,
                businessCity: contactForm.businessCity,
                businessState: contactForm.businessState,
                businessZipcode: contactForm.businessZipcode,
                "g-recaptcha-response": token,
              },
              publicKey
            );
            
            setContactForm(defaultContactForm);
            setShowThankYou(true);
          } catch (error) {
            console.error(error);
            alert("Failed to send email");
          } finally {
            setIsSubmitting(false);
          }
    };


    const renderStep = () => {
        switch (step) {
            case 1:
                return <Info contactForm={contactForm} handleFormChange={handleFormChange}/>;
            case 3:
                return <Address contactForm={contactForm} handleFormChange={handleFormChange} />;
            case 4:
                return <Employment contactForm={contactForm} handleFormChange={handleFormChange} />;
            case 2:
                return <Identity contactForm={contactForm} handleFormChange={handleFormChange} handleChangeBirthDate={handleChangeBirthDate} />;
            case 5:
                return <Business contactForm={contactForm} handleFormChange={handleFormChange} />;
            case 6:
                return <BusinessAddress contactForm={contactForm} handleFormChange={handleFormChange} />;
            default:
                return <Info />;
        }
    };

    // useEffect(() => {
    //     let timeout;
    
    //     if (status === true || false) {
    //       // Show the info message for 10 seconds
    //       timeout = setTimeout(() => {
    //         setAlertPrompt(null);
    //       }, 10000);
    //     }
    
    //     return () => {
    //       if (timeout) {
    //         clearTimeout(timeout);
    //         setStatus(null);
    //       }
    //     };
    //   }, [status]);

    return (
        <div className="flex lg:flex-nowrap flex-wrap justify-between items-center">
            {/* Form container */}
            <div className=" flex justify-center items-start flex-col lg:w-[40%] w-full">
                <div className="lg:text-[45px] text-[32px] font-[700] mb-5">
                    Explore Your Financing Options
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-steps flex-wrap mb-10">
                        <span className={step >= 1 ? (step === 1 ? 'active' : 'completed') : ''} style={{ paddingLeft: 0, cursor: "pointer" }} onClick={() => moveStep(1)}>Info</span> &gt;
                        <span className={step >= 2 ? (step === 4 ? 'active' : 'completed') : ''} onClick={() => moveStep(2)} style={{cursor: "pointer"}}>Identity</span> &gt;
                        <span className={step >= 3 ? (step === 2 ? 'active' : 'completed') : ''} onClick={() => moveStep(3)} style={{cursor: "pointer"}}>Address</span> &gt;
                        <span className={step >= 4 ? (step === 3 ? 'active' : 'completed') : ''} onClick={() => moveStep(4)} style={{cursor: "pointer"}}>Employment</span> &gt;
                        <span className={step >= 5 ? (step === 5 ? 'active' : 'completed') : ''} onClick={() => moveStep(5)} style={{cursor: "pointer"}}>Business</span> &gt;
                        <span className={step >= 6 ? (step === 5 ? 'active' : 'completed') : ''} onClick={() => moveStep(6)} style={{cursor: "pointer"}}>Business Address</span>
                        {/* <span className={step >= 1 ? (step === 1 ? 'active' : 'completed') : ''} style={{ padding: 0 }}>Info</span> &gt;
                        <span className={step >= 2 ? (step === 4 ? 'active' : 'completed') : ''} >Identity</span> &gt;
                        <span className={step >= 3 ? (step === 2 ? 'active' : 'completed') : ''} >Address</span> &gt;
                        <span className={step >= 4 ? (step === 3 ? 'active' : 'completed') : ''} >Employment</span> &gt;
                        <span className={step >= 5 ? (step === 5 ? 'active' : 'completed') : ''} >Business</span> &gt;
                        <span className={step >= 6 ? (step === 5 ? 'active' : 'completed') : ''} >Business Address</span> */}
                    </div>
                    <div className="form-content lg:w-[540px] w-[324px]">
                        {renderStep()}
                        {step === 6 && <ReCAPTCHA
                            sitekey="6LeCaGEqAAAAAJJ7P_sWyjZ1PyGuQLHKnv3m2pS-"
                            ref={refCaptcha}
                            onChange={() => setStatus(null)}
                            className='mt-3'
                        />}
                    </div>
                    <div>
                        {step < 6 && <button
                            className={`${activeButtonSteps["step" + step] === true ? "bg-black hover:bg-[#F6B000]" : "bg-slate-300"} submitButton text-white lg:w-[540px] md:w-[540px] w-[325px] h-[56px] flex justify-center items-center text-[16px] font-[500] mt-[30.4px] rounded-[10px] transition-all duration-300 hover:text-white gap-3 relative`}
                            type='button'
                            disabled={activeButtonSteps["step" + step] === true ? false : true}
                            onClick={nextStep}
                        >
                            Next
                        </button>}
                        {step === 6 && <button
                            className={`${activeButtonSteps["step6"] === true ? "bg-black hover:bg-[#F6B000]" : "bg-slate-300"} submitButton text-white lg:w-[540px] md:w-[540px] w-[325px] h-[56px] flex justify-center items-center text-[16px] font-[500] mt-[10px] rounded-[10px] transition-all duration-300 hover:text-white gap-3 relative`}
                            type='submit'
                            disabled={activeButtonSteps["step6"] === true ? false : true}
                        >
                            Submit {isSubmitting ? "..." : ""}
                        </button>}
                    </div>
                </form>
            </div>

            <div className="lg:w-[610px] lg:block hidden w-full  mt-6 lg:mt-0">
                <img className="lg:w-[610px]  w-[375px] lg:h-[580px] h-[243.75px] rounded-[15px] mx-auto" src={require('../../../src/images/car contact us.png')} alt="" />
            </div>
            {showThankYou && <Thankyou onClose={() => setShowThankYou(false)} />}
        </div>
    );
};

// Step Components
const Info = ({ contactForm, handleFormChange }) => (
    <div className='lg:w-[505px]  lg:mt-0 md:mt-0 mt-10'>
        <div className="row1 flex gap-[20px]">
            <div className="">
                <input type="text" name='firstName' value={contactForm.firstName || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[157px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='First name' />
            </div>
            <div className="">
                <input type="text" name='lastName' value={contactForm.lastName || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Last name' />
            </div>
        </div>
        <div className="mt-4">
            <input type="email" name='email' value={contactForm.email || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Email' />
        </div>
        <div className="row1 flex gap-[20px] mt-4">
            <div className="">
                <input type="text" name='homePhone' value={contactForm.homePhone || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Home Phone' />
            </div>
            <div className="">
                <input type="text" name='cellPhone' value={contactForm.cellPhone || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Cell Phone' />
            </div>
        </div>
    </div>
);

const Identity = ({ contactForm, handleFormChange, handleChangeBirthDate }) => (
    <div>
        <div className="row1 flex gap-[20px]">
            <div className="">
                <input type="text" name='ssn' value={contactForm.ssn || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='SSN' />
            </div>
            <div className="">
                <div className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500] border-b outline-none'>
                    <DatePicker
                        selected={contactForm.birth || ''}
                        name='birth'
                        onChange={(date) => handleChangeBirthDate(date)}
                        className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500] border-b'
                        placeholderText='Date of Birth'
                    />
                </div>
            </div>
        </div>
        <div className="row1 flex gap-[20px] mt-4">
            <div className="">
                <input type="text" name='rentAmount' value={contactForm.rentAmount || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[157px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Own/Rent/Mortgage Amount' />
            </div>
            <div className="">
                <input type="text" name='rentTime' value={contactForm.rentTime || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='How Long?' />
            </div>
        </div>
    </div>
);

const Address = ({ contactForm, handleFormChange }) => (
    <div>
        <div className=''>
            <input type="text" name='previousAddress' value={contactForm.previousAddress || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Previous Address (if less than 2 years at current residence)' />
        </div>
        <div className='mt-4'>
            <input type="text" name='address' value={contactForm.address || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Home Address' />
        </div>
        <div className="gap-[10px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-4">
            <div className="col-span-1 sm:col-span-2">
                <input type="text" name='city' value={contactForm.city || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='City' />
            </div>
            <div>
                <input type="text" name='state' value={contactForm.state || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='State' />
            </div>
            <div>
                <input type="text" name='zipCode' value={contactForm.zipCode || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='Zip Code' />
            </div>
        </div>
    </div>
);

const Employment = ({ contactForm, handleFormChange }) => (
    <div>
        <div className="row1 flex gap-[20px]">
            <div className="">
                <input type="text" name='placeEmployment' value={contactForm.placeEmployment || ''} onChange={(e) => handleFormChange(e)}  className='lg:w-[260px] md:w-[260px] w-[157px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Place of Employment' />
            </div>
            <div className="">
                <input type="text" name='employmentTime' value={contactForm.employmentTime || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='How many years?' />
            </div>
        </div>
        <div className='mt-4'>
            <input type="text" name='employmentAddress' value={contactForm.employmentAddress || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Employment Address' />
        </div>
        <div className="gap-[10px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-4">
            <div className="col-span-1 sm:col-span-2">
                <input type="text" name='employmentCity' value={contactForm.employmentCity || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='City' />
            </div>
            <div>
                <input type="text" name='employmentState' value={contactForm.employmentState || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='State' />
            </div>
            <div>
                <input type="text" name='employmentZipcode' value={contactForm.employmentZipcode || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='Zip Code' />
            </div>
        </div>
        <div className="row1 flex gap-[20px] mt-4">
            <div className="">
                <input type="text" name='occupation' value={contactForm.occupation || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[157px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Occupation' />
            </div>
            <div className="">
                <input type="text" name='annualIncome' value={contactForm.annualIncome || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Annual Income ($)' />
            </div>
        </div>
    </div>
);

// New Business Step Component
const Business = ({ contactForm, handleFormChange }) => (
    <div>
        <div className=''>
            <input type="text" name='businessName' value={contactForm.businessName || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Business Name' />
        </div>
        <div className='mt-4'>
            <input type="text" name='businessType' value={contactForm.businessType || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Type of Business' />
        </div>
        <div className="row1 flex gap-[20px] mt-4">
            <div className="">
                <input type="text" name='federalTaxId' value={contactForm.federalTaxId || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[157px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Federal Tax ID' />
            </div>
            <div className="">
                <input type="text" name='establishedYears' value={contactForm.establishedYears || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Years Established' />
            </div>
        </div>
        <div className='mt-4'>
            <input type="text" name='relationShipCompany' value={contactForm.relationShipCompany || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Your Relationship to the Company' />
        </div>
        {/* <input type="text" className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Phone Number' />
        <input type="text" className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Email' />
        <input type="text" className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Relation with Business' /> */}
    </div>
);

const BusinessAddress = ({ contactForm, handleFormChange }) => (
    <div>
        <div className="row1 flex gap-[20px]">
            <div className="">
                <input type="text" name='businessPhone' value={contactForm.businessPhone || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[157px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Primary Phone Number' />
            </div>
            <div className="">
                <input type="text" name='businessEmail' value={contactForm.businessEmail || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[260px] md:w-[260px] w-[152px] h-[54px] border-b outline-none text-[16px] font-[500] ' placeholder='Email' />
            </div>
        </div>
        <div className='mt-4'>
            <input type="text" name='businessAddress' value={contactForm.businessAddress || ''} onChange={(e) => handleFormChange(e)} className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Street Address' />
        </div>
        <div className="gap-[10px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-4">
            <div className="col-span-1 sm:col-span-2">
                <input type="text" name='businessCity' value={contactForm.businessCity || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='City' />
            </div>
            <div>
                <input type="text" name='businessState' value={contactForm.businessState || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='State' />
            </div>
            <div>
                <input type="text" name='businessZipcode' value={contactForm.businessZipcode || ''} onChange={(e) => handleFormChange(e)} className='h-[54px] border-b outline-none text-[16px] font-[500] w-full' placeholder='Zip Code' />
            </div>
        </div>
        {/* <input type="text" className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Phone Number' />
        <input type="text" className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Email' />
        <input type="text" className='lg:w-[540px] md:w-[540px] w-[324px] h-[54px] border-b outline-none text-[16px] font-[500]' placeholder='Relation with Business' /> */}
    </div>
);

export default ContactForm;
