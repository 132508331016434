import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from './HeroSection.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './RangeSlider.css'
// import { img } from 'framer-motion/client';
// import Jeep from './icons/Jeep';
// import Merce from './icons/Merce';
// import CircleIcon from './icons/Circle';
// import AIcon from './icons/AIcon';
// import Benz from './icons/Benz';
// import Tesla from './icons/Tesla';

// import Oval from './icons/Oval'
// import OvalO from './icons/OvalO';
// import Hunda from './icons/Hunda';
// import VerticalBox from './icons/VerticalBox';
// import Animal from './icons/Animal';
// import KN from './icons/KN';
// import Bugati from './icons/Bugati';
// import OvalArrow from './icons/OvalArrow';
// import OvalTriangle from './icons/OvalTriangle';
// import DoubleTriangle from './icons/DoubleTriangle';
// import CircleImage from './icons/CircleImage';
// import Suzuki from './icons/Suzuki'
// import DoubleLine from './icons/DoubleLine'
// import DotTriangle from './icons/DotTriangle'
// import Hunda2 from './icons/Hunda2'
// import All from './icons/All';
// import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'; // Import new icons
import { context } from '../../../context/context';
import { div } from 'framer-motion/client';

const vehicleModels = {
    "Ford": ["F-150", "Mustang", "Explorer", "Escape", "Bronco", "Expedition", "Edge", "Ranger", "Maverick", "Super Duty"],
    "Chevrolet": ["Silverado", "Corvette", "Camaro", "Tahoe", "Suburban", "Malibu", "Blazer", "Equinox", "Traverse", "Colorado", "Trailblazer"],
    "Toyota": ["Camry", "Corolla", "RAV4", "Tacoma", "Tundra", "Highlander", "4Runner", "Prius", "Sienna", "Land Cruiser"],
    "Honda": ["Accord", "Civic", "CR-V", "Pilot", "Odyssey", "Passport", "HR-V", "Ridgeline", "Insight"],
    "Jeep": ["Wrangler", "Grand Cherokee", "Cherokee", "Gladiator", "Renegade", "Compass", "Wagoneer"],
    "Tesla": ["Model 3", "Model S", "Model X", "Model Y", "Cybertruck (Upcoming)", "Roadster (Upcoming)"],
    "Ram": ["1500", "2500", "3500", "ProMaster", "TRX", "ProMaster City"],
    "Nissan": ["Altima", "Sentra", "Rogue", "Murano", "Pathfinder", "Frontier", "Titan", "Maxima", "Armada", "Kicks", "Leaf"],
    "BMW": ["3 Series", "5 Series", "7 Series", "X1", "X3", "X5", "X7", "4 Series", "M3", "M4", "M4", "Z4", "i3 (Discontinued)", "i4", "iX", "M8"],
    "Mercedes-Benz": ["A-Class", "C-Class", "E-Class", "S-Class", "G-Class", "GLA", "GLB", "GLC", "GLE", "GLS", "AMG GT", "EQS (Electric)", "EQE (Electric)"],
    "Subaru": ["Outback", "Forester", "Ascent", "Impreza", "WRX", "Crosstrek", "Legacy", "BRZ"],
    "GMC": ["Sierra 1500", "Sierra 2500HD", "Yukon", "Acadia", "Terrain", "Canyon", "Hummer EV"],
    "Dodge": ["Challenger", "Charger", "Durango", "Journey (Discontinued)", "Hornet", "Viper (Discontinued)", "Grand Caravan (Discontinued)"],
    "Volkswagen": ["Jetta", "Passat", "Golf", "Tiguan", "Atlas", "ID.4 (Electric)", "Arteon", "Taos"],
    "Hyundai": ["Elantra", "Sonata", "Tucson", "Santa Fe", "Palisade", "Kona", "Venue", "Ioniq 5 (Electric)", "Veloster N"],
    "Ferrari": ["488", "F8 Tributo", "SF90 Stradale", "812 Superfast", "Roma", "Portofino", "LaFerrari", "Purosangue (SUV)"],
    "Lamborghini": ["Aventador", "Huracán", "Urus", "Sián", "Revuelto (Upcoming)"],
    "Porsche": ["911", "Cayenne", "Macan", "Panamera", "Taycan (Electric)", "718 Boxster", "718 Cayman"],
    "Aston Martin": ["Vantage", "DB11", "DBX", "DBS Superleggera", "Valhalla (Upcoming)", "Valkyrie"],
    "McLaren": ["720S", "570S", "GT", "600LT", "Artura (Hybrid)", "Senna", "Speedtail"],
    "Rolls-Royce": ["Phantom", "Ghost", "Wraith", "Dawn", "Cullinan", "Spectre (Upcoming Electric)"],
    "Bentley": ["Bentayga", "Continental GT", "Flying Spur", "Mulsanne (Discontinued)"],
    "Maserati": ["Ghibli", "Quattroporte", "Levante", "MC20", "GranTurismo (Upcoming)"],
    "Bugatti": ["Chiron", "Veyron (Discontinued)", "Divo", "Centodieci", "Bolide"],
    "Lotus": ["Evora", "Elise", "Exige", "Emira",],
    "Audi": ["A3","A4","A5","A6","A7","A8","Q3","Q5","Q7","Q8","TT","R8","e-tron","e-tron GT"],
    "Lexus": ["IS","ES","LS","NX","RX","GX","LX","RC","LC","UX","RZ"],
    "Volvo": ["S60","S90","V60","V90","XC40","XC60","XC90","C40 Recharge","EX90"],
    "Kia": ["Forte","K5","Stinger","Soul","Seltos","Sportage","Sorento","Telluride","Carnival","Niro","EV6","EV9"],
    "Alfa Romeo": ["Giulia","Stelvio","Tonale","Giulia Quadrifoglio","Stelvio Quadrifoglio"],
    "Jaguar": ["XE","XF","F-Type","E-PACE","F-PACE","I-PACE"],
    "Land Rover": ["Range Rover","Range Rover Sport","Range Rover Velar","Range Rover Evoque","Discovery","Defender"],
    "Lincoln": ["Corsair","Nautilus","Aviator","Navigator"],
    "Mazda": ["Mazda3","Mazda6","CX-3","CX-30","CX-5","CX-50","CX-9","MX-5 Miata"]
};

export const RangeSlider = ({ min, max, value, step, onChange }) => {
    const [minValue, setMinValue] = useState(value ? value.min : min);
    const [maxValue, setMaxValue] = useState(value ? value.max : max);
    const sliderTrackRef = useRef(null);

    useEffect(() => {
        if (value) {
            setMinValue(value.min);
            setMaxValue(value.max);
        }
    }, [value]);

    const handleMinChange = (newMinVal) => {
        const updatedMin = Math.min(newMinVal, maxValue - step);
        setMinValue(updatedMin);
        onChange({ min: updatedMin, max: maxValue });
    };

    const handleMaxChange = (newMaxVal) => {
        const updatedMax = Math.max(newMaxVal, minValue + step);
        setMaxValue(updatedMax);
        onChange({ min: minValue, max: updatedMax });
    };

    const handleThumbMove = (e, type, isTouch = false) => {
        if (!sliderTrackRef.current) return; // Ensure the slider track is available
        const sliderRect = sliderTrackRef.current.getBoundingClientRect();
        const sliderWidth = sliderRect.width;

        const clientX = isTouch ? e.touches[0].clientX : e.clientX;
        const relativeX = clientX - sliderRect.left;
        const newValue = (relativeX / sliderWidth) * (max - min) + min;

        if (type === 'min') {
            handleMinChange(Math.max(min, Math.min(newValue, maxValue - step)));
        } else if (type === 'max') {
            handleMaxChange(Math.min(max, Math.max(newValue, minValue + step)));
        }
    };

    const startThumbMove = (e, type, isTouch = false) => {
        const moveEvent = isTouch ? 'touchmove' : 'mousemove';
        const endEvent = isTouch ? 'touchend' : 'mouseup';

        const onMove = (event) => handleThumbMove(event, type, isTouch);
        const onEnd = () => {
            document.removeEventListener(moveEvent, onMove);
            document.removeEventListener(endEvent, onEnd);
        };

        document.addEventListener(moveEvent, onMove);
        document.addEventListener(endEvent, onEnd, { once: true });
    };

    const minPos = ((minValue - min) / (max - min)) * 100;
    const maxPos = ((maxValue - min) / (max - min)) * 100;

    return (
        <div className="slider-container relative  h-[170px] text-white lg:bg-[#2C2C2C] bg-transparent">
            <div className="mt-[14.35px] text-[14px] font-[500] text-white flex items-center justify-between">Price range
                <div className="hidden">
                    <img src={require("../../../images/Frame (8).png")} alt="" />
            </div></div>

            {/* Slider track with min/max labels */}
            <div className="relative flex items-center">
                {/* <span className="absolute left-0 text-sm font-medium text-gray-500">${min}</span> */}
                <div className="slider-track relative w-full h-1 mx-4 rounded" ref={sliderTrackRef}>
                    <div className="bg-lightgray absolute top-[6px] h-1 w-full"></div>
                    <div
                        className="slider-range absolute bg-blue-500 h-2"
                        style={{
                            left: `${minPos}%`,
                            width: `${maxPos - minPos}%`,
                        }}
                    />
                    {/* Thumbs */}
                    <div
                        className="slider-thumb absolute w-4 h-4 bg-blue-500 rounded-full cursor-pointer"
                        style={{ left: `calc(${minPos}% - 8px)` }}
                        onMouseDown={(e) => startThumbMove(e, 'min')}
                        onTouchStart={(e) => startThumbMove(e, 'min', true)}
                    />
                    <div
                        className="slider-thumb absolute w-4 h-4 bg-blue-500 rounded-full cursor-pointer"
                        style={{ left: `calc(${maxPos}% - 8px)` }}
                        onMouseDown={(e) => startThumbMove(e, 'max')}
                        onTouchStart={(e) => startThumbMove(e, 'max', true)}
                    />
                </div>
                {/* <span className="absolute right-0 text-sm font-medium text-gray-500">${max}</span> */}
            </div>

            {/* Displaying selected min/max values */}
            <div className="ranges py-5 flex justify-between">
                <div className="flex flex-col justify-center items-center text-gray-500">
                    <span className="font-medium text-sm">Min</span>
                    <div className="px-3 py-3 bg-gray-100 rounded-xl">${minValue.toFixed(2)}</div>
                </div>
                <div className="flex flex-col justify-center items-center text-gray-500">
                    <span className="font-medium text-sm">Max</span>
                    <div className="px-3 py-3 bg-gray-100 rounded-xl">${maxValue.toFixed(2)}</div>
                </div>
            </div>
        </div>
    );
};






export const CustomDropdown = ({ options, defaultText, selectedValues, onSelect, width, name, left, priceRange, handlePriceChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { setFeatureIndex } = useContext(context);
    useEffect(() => {
        if (isOpen)
            setFeatureIndex(-1)
        else
            setFeatureIndex(1)
    },[isOpen])
    const [hoveredValue, setHoveredValue] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const toggleOption = (value, label) => {
        if (selectedValues.includes(value)) {
            onSelect(value, false); // Deselect
        } else {
            onSelect(value, true); // Select
        }
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-[166px] " ref={dropdownRef}>
            <div
                className={styles.customSelect}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className='text-change'>
                    {defaultText}
                </span>
                <img src={require("../../../images/heroicon.png")} className='w-[20px] h-[20px] text-img1' alt="" />
                <img src={require("../../../images/coloricondown.png")} className='w-[20px] h-[20px] text-img2 hidden rotate-180' alt="" />
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.ul
                        initial={{ opacity: 0, y: -10, }} // Start hidden and slightly above
                        animate={{ opacity: 1, y: 0 }} // Animate to visible and positioned correctly
                        exit={{ opacity: 0, y: -10 }} // Animate exit
                        transition={{ duration: 0.3 }} // Control the speed of the animation
                        className={`flex absolute justify-start items-center bg-white text-black flex-wrap top-[90px] rounded-2xl p-3 font-[500] gap-[5px] ${name === "Price"? 'bg-[#2C2C2C]': ''}`}
                        style={{ width: width, left: left, backgroundColor: name === "Price"? "#2C2C2C": "white" }}
                    >
                        {name === "Make"

                            ? <div className='grid grid-cols-2 py-2 z-20' style={{ zIndex: 9999999 }}> {options.map((option) => (

                                <li
                                    key={option.value}
                                    className={`${styles.option} ${selectedValues.includes(option.value) ? styles.selected : ''}`}
                                    onClick={() => toggleOption(option.value, option.label)}
                                    onMouseEnter={() => setHoveredValue(option.value)}
                                    onMouseLeave={() => setHoveredValue(null)}
                                >
                                    {option.value}
                                    <div className="">
                                        <img src={require("../../../images/Vector (1).png")} alt="" />
                                    </div>
                                </li>

                            ))}
                            </div>
                            : name === "Price" ? (
                                <li className="bg-[#2C2C2C] w-[401px]">
                                    <RangeSlider
                                        min={0}
                                        max={10000}
                                        value={priceRange}
                                        step={1}
                                        onChange={handlePriceChange}
                                    />
                                </li>
                            ) : (
                                <div>
                                    {options.map((option) => (

                                        <li
                                            key={option.value}
                                            className={`${styles.option} ${selectedValues.includes(option.value) ? styles.selected : ''}`}
                                            onClick={() => toggleOption(option.value, option.label)}
                                            onMouseEnter={() => setHoveredValue(option.value)}
                                            onMouseLeave={() => setHoveredValue(null)}
                                        >
                                            {option.value}
                                            <div className="">
                                                <img src={require("../../../images/Vector (1).png")} alt="" />
                                            </div>
                                        </li>

                                    ))}
                                </div>
                            )}
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    );
};


const HeroSection = () => {
    const navigate = useNavigate();
    const [isOverlayVisible, setIsOverlayVisible] = useState(false); // State for overlay visibility
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedType, setSelectedType] = useState(null);

    const toggleOverlay = () => {
        setIsOverlayVisible(!isOverlayVisible); // Toggle overlay visibility
    };

    const [selections, setSelections] = useState([
        // {
        //     name: "Categories",
        //     options: [
        //         { value: 'Sport', label: 'Sport' },
        //         { value: 'SUVs', label: 'SUVs' },
        //         { value: 'Hatchback', label: 'Hatchback' },
        //         { value: 'Crossover', label: 'Crossover' },
        //         { value: 'Sedan', label: 'Sedan' },
        //         { value: 'Electric', label: 'Electric' },
        //         { value: 'Hybrid', label: 'Hybrid' },
        //         { value: 'Pickup', label: 'Pickup' },
        //     ],
        //     default: "All categories",
        //     width: '500px',
        //     left: "0px"
        // },
        {
            name: "Make",
            options: [
                { id: 1, value: 'Ford', label: 'Ford' },
                { id: 2, value: 'Chevrolet', label: 'Chevrolet' },
                { id: 3, value: 'Toyota', label: 'Toyota'},
                { id: 4, value: 'Honda', label: 'Honda'},
                { id: 5, value: 'Jeep', label: 'Jeep'},
                { id: 6, value: 'Tesla', label: 'Tesla'},
                { id: 7, value: 'Ram', label: 'Ram'},
                { id: 8, value: 'Nissan', label: 'Nissan'},
                { id: 9, value: 'BMW', label: 'BMW'},
                { id: 10, value: 'Mercedes-Benz', label: 'Mercedes-Benz'},
                { id: 11, value: 'Subaru', label: 'Subaru'},
                { id: 12, value: 'GMC', label: 'GMC'},
                { id: 13, value: 'Dodge', label: 'Dodge'},
                { id: 14, value: 'Volkswagen', label: 'Volkswagen'},
                { id: 15, value: 'Hyundai', label: 'Hyundai'},
                { id: 16, value: 'Ferrari', label: 'Ferrari'},
                { id: 17, value: 'Lamborghini', label: 'Lamborghini'},
                { id: 18, value: 'Porsche', label: 'Porsche'},
                { id: 19, value: 'Aston Martin', label: 'Aston Martin'},
                { id: 20, value: 'McLaren', label: 'McLaren'},
                { id: 21, value: 'Rolls-Royce', label: 'Rolls-Royce'},
                { id: 22, value: 'Bentley', label: 'Bentley'},
                { id: 23, value: 'Maserati', label: 'Maserati'},
                { id: 24, value: 'Bugatti', label: 'Bugatti'},
                { id: 25, value: 'Lotus', label: 'Lotus'},
                { id: 26, value: 'Audi', label: 'Audi'},
                { id: 27, value: 'Lexus', label: 'Lexus'},
                { id: 28, value: 'Volvo', label: 'Volvo'},
                { id: 29, value: 'Kia', label: 'Kia'},
                { id: 30, value: 'Alfa Romeo', label: 'Alfa Romeo'},
                { id: 31, value: 'Jaguar', label: 'Jaguar'},
                { id: 32, value: 'Land Rover', label: 'Land Rover'},
                { id: 33, value: 'Lincoln', label: 'Lincoln'},
                { id: 34, value: 'Mazda', label: 'Mazda'},
            ],
            default: "All makes",
            width: '362px',
            left: "34px"
        },
        {
            name: "Model",
            options: [
                { value: 'All', label: 'All' },
            ],
            default: "All models",
            width: '190px',
            left: "176px"
        },
        {
            name: "BodyType",
            options: [
                { value: 'All', label: 'All' },
                { value: 'Coupe', label: 'Coupe' },
                { value: 'Sedan', label: 'Sedan' },
                { value: 'SUV', label: 'SUV' },
                { value: 'Crossover', label: 'Crossover' },
                { value: 'Convertible', label: 'Convertible' },
                { value: 'Van', label: 'Van' }
            ],
            default: "All types",
            width: '190px',
            left: "404px"
        },
        {
            name: "Price",
            options: [
                { value: '0-20000', label: '$0 - $20,000' },
                { value: '20000-40000', label: '$20,000 - $40,000' },
                { value: '40000+', label: '$40,000+' },
            ],
            default: "Any price",
            width: '441px',
            left: "554px"
        }
    ]);

    // const [openDropdown, setOpenDropdown] = useState(null);

    const { selectedValues, setSelectedValues, priceRange, setPriceRange, isFilter, setIsFilter } = useContext(context)

    // const [priceRange, setPriceRange] = useState({ min: 0, max: 100 });

    const handlePriceChange = (newRange) => {
        setPriceRange(newRange);
    };


    const handleSelect = (name, value, isSelected) => {
        setSelectedValues(prev => {
            const currentSelections = prev[name] || [];
            if (isSelected) {
                return { ...prev, [name]: [value] };
            } else {
                return { ...prev, [name]: currentSelections.filter(v => v !== value) };
            }
        });

        if(name === 'Make') {
            setSelectedBrand(value);
        } else if(name === 'Model') {
            console.log(value);
            setSelectedModel(value);
        } else if(name === 'BodyType') {
            setSelectedType(value);
        }
    };

    const handleFilterBtn = () => {
        setIsFilter(!isFilter);
        navigate("/view");
    }

    const formatPriceRange = (min, max) => {
        return `$${parseInt(min)} - $${parseInt(max)}`;
    };

    useEffect(() => {
        if (selectedBrand) {
            let tempModels = [];
            vehicleModels && vehicleModels[selectedBrand].map(item => {
                tempModels.push({value: item, label: item});
            });
            
            setSelections(prevSelections => {
                return prevSelections.map(selection => {
                    if (selection.name === "Model") {
                        return {
                            ...selection,
                            options: tempModels,
                        };
                    }

                    if(selection.name === "Make") {
                        return {
                            ...selection,
                            default: selectedBrand
                        };
                    }

                    return selection;
                });
            });
        }

        if (selectedModel) {
            setSelections(prevSelections => {
                return prevSelections.map(selection => {
                    if(selection.name === "Model") {
                        return {
                            ...selection,
                            default: selectedModel
                        };
                    }
                    
                    return selection;
                });
            });
        }

        
        if (selectedType) {
            setSelections(prevSelections => {
                return prevSelections.map(selection => {
                    if(selection.name === "BodyType") {
                        return {
                            ...selection,
                            default: selectedType
                        };
                    }
                    
                    return selection;
                });
            });
        }

        if(priceRange) {
            if ((priceRange.max !== 10000 || priceRange.min > 0)&& (priceRange.min !== 0 || priceRange.max > 0)) {
                setSelections(prevSelections => {
                    return prevSelections.map(selection => {
                        if(selection.name === "Price") {
                            return {
                                ...selection,
                                default: formatPriceRange(priceRange.min, priceRange.max)
                            };
                        }
                        
                        return selection;
                    });
                });
            }
        }

    }, [selectedBrand, selectedModel, selectedType, priceRange]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    return (
        <div className='w-full h-[720px]  bg-black lg:p-[60px] md:p-[60px] p-[25px] flex justify-center'>
            <div className="w-[100%] h-[632px] relative ">
                <div className="absolute left-[4%] lg:top-[40px] md:top-[40px] top-[30px] space-y-5" style={{ zIndex: 9999 }}>
                    <img src={require("../../../images/tiktok.png")} alt="" className='w-[18px] h-[18px] space-y-5 ' />
                    <img src={require("../../../images/insta2.png")} alt="" className='w-[18px] h-[18px]  ' />
                    <img src={require("../../../images/solidfb.png")} alt="" className='w-[18px] h-[18px]  ' />
                </div>
                <img src={require("../../../images/hero.png")} style={{ borderRadius: '22px' }} className='w-full h-[632px] lg:block md:block hidden' alt="" />

                <img src={require("../../../images/responsiveHeroImage.png")} className='w-full h-[652px] lg:hidden md:hidden block' alt="" style={{ filter: "brightness(0.5)", borderRadius: '22px' }} />
                <div className="samllText absolute  lg:w-[560px] md:w-[460px] w-[219px] lg:right-[5%] md:right-[5%] right-[32px] lg:top-[50px]md:top-[50px] top-[30px] text-[8.76px] text-white lg:text-right md:text-right text-center font-[300] leading-[1.5] lg:opacity-100 opacity-50">
                    Explore our exclusive collection of exotic cars, each meticulously chosen to represent the epitome of automotive <br /> engineering and design. From sleek sports cars to powerful luxury SUVs, our selection caters to every discerning taste.
                </div>
                <div className="heading lg:w-[1030px] w-[325px] absolute top-[22%] left-[50%] transform -translate-x-1/2  text-white flex flex-col items-center">
                    <div className='font-[700] lg:text-[60px] text-[35px] text-center tracking-[0.02em] leading-[1.1] lg:w-[950px] w-[300px] lg:mt-0 mt-[70px] lg:ml-0 ml-[10px]'>
                        Drive Your Dreams with The Wheel Deal Auto Leasing
                    </div>
                    <br />
                    <div className="lg:text-[16px] text-[12px] font-[500] text-center">
                        Discover the perfect vehicle for you with our seamless leasing experience.
                    </div>

                    <div className=" relative w-[1030px] h-[78px] bg-white rounded-[15px] mt-10  justify-between items-center lg:flex hidden pl-5">
                        {selections.map((sel) => (
                            <div key={sel.name} className="hoverBox w-[166px] hover:bg-lightgray transition-all duration-200 rounded">
                                <div className="ml-[16px] text-start font-[500] text-[12px] text-[#797979] mt-[4px] ">
                                    {sel.name}
                                </div>
                                <div className=" ml-[16px] -mt-[4px]">
                                    <CustomDropdown
                                        options={sel.options}
                                        defaultText={sel.default}
                                        selectedValues={selectedValues[sel.name] || []}
                                        onSelect={(value, isSelected) => handleSelect(sel.name, value, isSelected)}
                                        width={sel.width}
                                        handlePriceChange={setPriceRange}
                                        priceRange={priceRange}
                                        name={sel.name}
                                        left={sel.left}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="">
                            <button onClick={() => handleFilterBtn()} className='w-[162px] h-[56px] flex justify-center items-center bg-[#000] text-[16px] font-[500] rounded-[10px] mx-[15px]
                            hover:bg-[#F6B000] transition-all duration-300'>
                                Browse vehicles
                            </button>
                        </div>
                    </div>
                    <div className="w-[285px] mt-[60px] h-[60px] ml-[20px] bg-white px-2 flex items-center rounded-[15px] lg:hidden md:hidden" onClick={toggleOverlay}>
                        <img src={require('../../../images/SeachIcon.png')} alt="" className='w-[44px] h-[44px]' />
                        <div className="text-[16px] font-[500] text-[#000000] ml-5">
                            Search for a car...
                        </div>
                    </div>
                </div>
                {isOverlayVisible && (
                    <motion.div
                        className='bg-white fixed rounded-t-[30px] top-0 w-[100vw] left-0 px-[25px]'
                        style={{ zIndex: 999999, height: '100vh', width: "100vw", overflowX: "hidden", overflowY: 'auto' }}
                        initial={{ y: '100%' }} // Start from bottom
                        animate={{ y: 0 }} // Animate to original position
                        exit={{ y: '100%' }} // Exit to bottom
                        transition={{ duration: 0.3 }} // Animation duration
                    >
                        <div className="flex justify-between mt-[37.5px] items-center">
                            <div className="text-[30px] font-[700] text-black">
                                Search
                            </div>
                            <img
                                src={require("../../../images/crossSearch.png")}
                                alt=""
                                className='w-[32px] h-[28.84px]'
                                onClick={toggleOverlay} // Close overlay on click
                            />
                        </div>

                        <FAQ
                            selections={selections}
                            priceRange={priceRange}
                            handlePriceChange={handlePriceChange}
                            selectedValues={selectedValues}
                            onSelect={handleSelect}
                            startDate={startDate}
                            setEndDate={setEndDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                        />
                        <div className="button">
                            <button onClick={() => handleFilterBtn()} className='w-full h-[56px] flex justify-center items-center font-[500] bg-black text-white rounded-[10px] mt-20 mx-auto'>
                                Show results
                            </button>
                        </div>
                    </motion.div>
                )}
            </div>

        </div>
    )
}

export default HeroSection



export const FAQ = ({ selectedValues, priceRange, handlePriceChange, selections, onSelect, startDate, setStartDate, endDate, setEndDate }) => {


    const selectionsWithDate = [
        // {
        //     name: "Date",
        //     options: []
        // },
        ...selections
    ];

    const [openQuestions, setOpenQuestions] = useState([]);
    const [hoveredValue, setHoveredValue] = useState(null);

    const toggleQuestion = (index) => {
        if (openQuestions.includes(index)) {
            setOpenQuestions(openQuestions.filter((q) => q !== index));
        } else {
            setOpenQuestions([...openQuestions, index]);
        }
    };

    const toggleOption = (name, value, label) => {
        const currentSelections = selectedValues[name] || []; // Ensure currentSelections is an array
        if (currentSelections.includes(value)) { // Use currentSelections instead
            onSelect(name, value, false); // Deselect
        } else {
            onSelect(name, value, true); // Select
        }
    };

    return (
        <div className="lg:-mt-10 md:-mt-10 mt-[10%]">
            <div className="lg:w-[600px] md:w-[600px] w-[325px] mx-auto">
                {selectionsWithDate && selectionsWithDate.map((selection, index) => (
                    <div key={selection.name} className="w-full ">
                        <div className="w-full my-5" />
                        <div
                            className={`flex justify-between items-center w-full pb-2 cursor-pointer 
                                border-b lg:border-[#E9E9E9]  transition-all duration-300
                                `}
                            onClick={() => toggleQuestion(index)}
                        >
                            <div>
                                <p className={`lg:text-[18px] py-2 md:text-[18px] text-[16px]  font-[700] text-start text-[#636363]`} >
                                    {selection.name === "BodyType" ? "Body Type" : selection.name}
                                </p>
                            </div>
                            <button
                                aria-label="toggler"
                                className="focus:outline-none"
                                onClick={() => toggleQuestion(index)}
                            >
                                {openQuestions.includes(index) ? (
                                    <img src={require("../../../images/dateupicons.png")} alt='date' className='w-[24px] h-[24px]  rotate-180' />
                                ) : (
                                    <img src={require("../../../images/dateupicons.png")} alt='date' className='w-[24px] h-[24px]' />
                                )}
                            </button>
                        </div>
                        <div className={`${openQuestions.includes(index) ? 'block' : 'hidden'} mt-6 w-full`}>
                            <motion.ul
                                initial={{ opacity: 0, y: -10, x: -10 }} // Start hidden and slightly above
                                animate={{ opacity: 1, y: 0, x: 0 }} // Animate to visible and positioned correctly
                                exit={{ opacity: 0, y: -10 }} // Animate exit
                                transition={{ duration: 0.3 }} // Control the speed of the animation
                                className={`flex justify-start items-center bg-white text-black flex-wrap font-[500] gap-[5px]`}
                            >
                                {selection.name === "Price" ? (
                                        <li className="w-[100%] price-range-slider">
                                            <RangeSlider
                                                min={0}
                                                max={10000}
                                                value={priceRange}
                                                step={1}
                                                onChange={handlePriceChange}
                                            />
                                        </li>
                                    ) : selection.name === "Date" ? (
                                        <div className="w-full space-y-4 filter-datepickers">
                                            <div className="text-[#767676] w-[100%]">
                                                <div className='text-[12px] text-[#767676] font-[500] flex gap-3 space-y-2 mb-2'>
                                                    <img src={require("../../../images/calender.png")} alt="" className='w-[16px] h-[16px]' />
                                                    Select pick-up date:
                                                </div>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    dateFormat="yyyy/MM/dd"
                                                    placeholderText="YYYY / MM / DD"
                                                    className="bg-lightgray rounded-[10px] p-3 outline-none w-full"
                                                />
                                            </div>
                                            <div className="text-[#767676] w-[100%]">
                                                <div className='text-[12px] text-[#767676] font-[500] flex gap-3 space-y-2 mb-2'>
                                                    <img src={require("../../../images/calender.png")} alt="" className='w-[16px] h-[16px]' />
                                                    Select pick-up date:
                                                </div>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    dateFormat="yyyy/MM/dd"
                                                    placeholderText="YYYY / MM / DD"
                                                    className="bg-lightgray rounded-[10px] p-3 outline-none w-[100%]"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {
                                                selection.options.map((option) => (
                                                    <li
                                                        key={option.value}
                                                        className={`lg:w-[166px] w-[135px] ${styles.option} ${selectedValues[selection.name] && selectedValues[selection.name].includes(option.value) ? styles.selected : ''}`}
                                                        onClick={() => toggleOption(selection.name, option.value, option.label)}
                                                    >
                                                        {option.label}
                                                    </li>
                                                ))
                                            }
                                        </>
                                    )}
                            </motion.ul>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};